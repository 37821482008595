import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Getting Started`}</h1>
    <p>{`This feature is intended to boost your sales by showing buyable products that match the content of the video. To put it in a nutshell: what the Shoppable Video function does, is that you just need to link the respective products to a video and define the time slots when the products should appear. `}</p>
    <p>{`Do you have your video and content items already in place? Excellent, you can now begin with the first step.`}</p>
    <h2>{`Steps`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create a new content item of the type Video `}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "930px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1e7f915d30b137d2fdc0c1542ec4bd2e/defcb/01.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "99.33333333333331%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADQElEQVQ4y63QXUxbdRzG8ZPsyhsvjBfGGHUuGrchFFiYpsCAgnR9YdBS6AsFRoRYmAY2ZG6Vvg4mo7SlLbQwyFjcS5ZtyW68N5te6A1ZzLyYUcd0G5sMagpUoO3X9OCwRo1Z0pN88uT/O8//l5wj3Lp+m7mf5lhZXSEajZJIJkg/sViM+fmHYm4kEpBY47v5GMqpWaa+vgepDVZWV4nH46zGNzORSCDY93tx1fg5oQnirPExoAlyUhviRF0A1wE/7tpRButDOJReIr3n+fb7OX6NLpNKJjlz7BJ2hZcBbVDsp+8KrTv6aNneR+trfRzccZSW7R/9aXP2JA0v9OCo87Hw8AEb62viVzg1AXTPfYD55V5MLx3B/EovQkuujdZcG6adVoxvHqc5p5+mnVaadn0iZvp8UGLH+MZxbIYxfr73gMdLv/FoYYGRQ2fpKj7J4XdH6Kny0F7kQjBIHOjzHBzVhfm08xzu92awmiaxNU/hbDtDR/kp9Hl26nf187FxnNs/3OH+o0XmF5a48+Ndfrl7n+hijBQpfH0XELR73KR1KEf5UBemszbEYcMEXXUhuhsjmMtOUStxUJfvRC8dpMcwTrd+nN6mCLPf3GIlvkwymRR/gd92BUH99iBpikI38nwXij1uDhlOY2mcwNIQwVTtQy8bQV85QqNsBFXRANUSF/ICJ19+Mcv6+u+sr22ICz32qwhy6RCi4iHkJZt55P3ztJum0KuDNCgD6BQBMdPUFR6xoygd4qsbN1lcesziYlRcOJheWLHPw5YyD+Wlw5iN0zQZp2gxT9OgDaNSBKhVh6hRBamSecWOrGyY6zdushxfYS2REhf2Wy8iFMu8bPIhlXkprfLT0XURY+sM5raz1DWeRqkJo9KExSyXjyKt8FJS6aWz+xzH7FexOq/hHvocrXECoah6lEx75QGqdJPItBNU1k8gVYZ4RxHckn7/pJu3z0tOiYec4mF2S4cplPkR8pUhMkmUId6SB8ndv0miCP1dRrdAPUahenxLgWoMYfeBMP8UyRB+KsLrmkmySXi1YZpsEl40zpBNwvPNn5FNwrNtF8gm4ZmOS/yr9v+Y/w9hm+Uy2yxX/tKZwfL0/gDHnalzAZ/juwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/1e7f915d30b137d2fdc0c1542ec4bd2e/e93cc/01.webp 300w", "/static/1e7f915d30b137d2fdc0c1542ec4bd2e/b0544/01.webp 600w", "/static/1e7f915d30b137d2fdc0c1542ec4bd2e/8867c/01.webp 930w"],
                  "sizes": "(max-width: 930px) 100vw, 930px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/1e7f915d30b137d2fdc0c1542ec4bd2e/eed55/01.png 300w", "/static/1e7f915d30b137d2fdc0c1542ec4bd2e/7491f/01.png 600w", "/static/1e7f915d30b137d2fdc0c1542ec4bd2e/defcb/01.png 930w"],
                  "sizes": "(max-width: 930px) 100vw, 930px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/1e7f915d30b137d2fdc0c1542ec4bd2e/defcb/01.png",
                  "alt": "01",
                  "title": "01",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
        <p parentName="li">{` and add a title for your video in the `}<em parentName="p">{`Name`}</em>{` field.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "932px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/220ec069e83a0b13e5f17de3ba675b53/639e1/02.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "99.33333333333331%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC80lEQVQ4y63TW0zTVxzA8ZPsyRejzsTEaNAli0MjFnGiCFGkWmmFAqVQKm3RGXVTZ2KCS8ShUC5tpEjvpVhQ2EZMTIzxadnivGUPZgnPGuKD8TYgwkSk/076NecfvFv1gZN88rue5P9/OOL2zTvcu38PRVEY+2+Ml2d0bJShoSHi8ThTU8/VeTKZREko6jyRUIgrcSbjkyqZJ0kiGrZ30FTmo6U8gLPUi6sipGo2+dW6xeSn1RxU65dRzuW+zN8k56LmqyM4lh6hRpL5klocS2vfqu2y94H4LtkXNRn1ODLqsX5zlB3pddhWHKM6vY7q5XVqdKz8GbnzuYRl1XFs3zo5Vt2Fa/8vHK+JUWeNqrHefhpHdhNypyrzBFWaTxNlWY2Ys5v4oSTAj+Yw+0uDHLZG1XioIkLlumZKMhsoW91I6epGTFlOTGtSE0XrWijKbqYwy4lO00BJjotDthh7zWH2loep0nqwaD1UTjPmtLJd3klB6Da42ZbrpjDPjW6DC6O2nZ8O9OMoj2Ap8mM2+KjSe9lRKHVQkuemWO6lIDZvbEO1qY38jSfRbvaw096D1RLFYe/GZAyyvjiExtxFenGYxVoviwo6PkjORG5BO1Ke9hQ5+R50RUH2HezHYu/B9l0vemOQWv8f/DpwF/+1Qdx/3sJzZRDPX++4Msipq4OItTovUrbOx9qtXnIMAbZWRCkwRdhS3okmv52Ll/6Rb4Ok+kqe87EjMg0BXgui0QdYuc3HqkI/mfoAywo6ON1/naGRYR4Nj/Jw+DEP/h1RPRoZ5clEnPFnCk8mFMbGnyFWGMO8L6LKKO0kbYuPC78PAP+TmBxnSnn6SjIxMf3FU+ofyJ74uixKKstMXaQVR9jtvIi//2/aeq/j6bvxSnvfDVw913DGrtIau0xL7DJiSUWMVNJkrOxmgbGTOfoQcw0h5urfNs8Q4ktDmHnTxELrGT5lUfVZFtt6P4uYb+9jJonZu35jJolZe84xk8QX359nJr0AW6K5BriF9ksAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/220ec069e83a0b13e5f17de3ba675b53/e93cc/02.webp 300w", "/static/220ec069e83a0b13e5f17de3ba675b53/b0544/02.webp 600w", "/static/220ec069e83a0b13e5f17de3ba675b53/c7a47/02.webp 932w"],
                  "sizes": "(max-width: 932px) 100vw, 932px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/220ec069e83a0b13e5f17de3ba675b53/eed55/02.png 300w", "/static/220ec069e83a0b13e5f17de3ba675b53/7491f/02.png 600w", "/static/220ec069e83a0b13e5f17de3ba675b53/639e1/02.png 932w"],
                  "sizes": "(max-width: 932px) 100vw, 932px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/220ec069e83a0b13e5f17de3ba675b53/639e1/02.png",
                  "alt": "02",
                  "title": "02",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Scroll down to the property `}<em parentName="p">{`Video File`}</em>{`  and upload your video, which must be in the format MP4.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "893px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3794804225f1d5ad345a2947edf93d1a/fa61a/04.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "98%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAABnElEQVQ4y62U226CQBRF+c62T2po/Ks+NemHGPvqJYJpiUgVFJWbgjd0N3vIEGyNSuskOwhzzpozZ/aoGBMPmjWB5Sd4eX3D0+MD6vU6VFW9rmcV1WoVlUpFPGu1GhTXsWEaHzCNT4y/LAS+jzAIENwgxk2nU9jjsdBsNoPy3myi3Wqj2+mir+vQNa2U+rqW5fV66LRaUHCncTweEYYhlDRNsd/vhdJzStNMF+aZu9vtRBuU1WqFeyiO46xC27YFOYqif4kwz/MyYBhFeR/+Ig5u3TTNbMt84TgcDidNlsHXDkMCB4MBFDbTdV1sNhvho8ViIcDz+Rzb7famyk+AhDQaDYxGIzHBE2MQoTK4FFAmF7db1n8nQN/38+1JPxUru9bPX0D6R3ppuVwKsZ8ES8mFLok5AsjL7ThOniiTWTHF33GSYL1e59+LIPnOeQFkZUmSXOwTY2heaa9zg2cggHS4dDmfbMFPccFz34viogJId1OGYQg/EsqDCm78T5RijrgplmVhOBwKU8tq/yJCyVFoGao4URYkc3jLvgExouvOZ5dlBQAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/3794804225f1d5ad345a2947edf93d1a/e93cc/04.webp 300w", "/static/3794804225f1d5ad345a2947edf93d1a/b0544/04.webp 600w", "/static/3794804225f1d5ad345a2947edf93d1a/8c4fa/04.webp 893w"],
                  "sizes": "(max-width: 893px) 100vw, 893px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/3794804225f1d5ad345a2947edf93d1a/eed55/04.png 300w", "/static/3794804225f1d5ad345a2947edf93d1a/7491f/04.png 600w", "/static/3794804225f1d5ad345a2947edf93d1a/fa61a/04.png 893w"],
                  "sizes": "(max-width: 893px) 100vw, 893px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/3794804225f1d5ad345a2947edf93d1a/fa61a/04.png",
                  "alt": "04",
                  "title": "04",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Scroll down to the property `}<em parentName="p">{`Layout Variant`}</em>{` and change the layout to `}<em parentName="p">{`Shoppable Video`}</em>{`.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "892px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b9c1f75b7d30276c30e268ca25aba88b/6de40/05.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "98%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACFklEQVQ4y6WT/W7aMBTF/aadQOr6FpO2J2v7dzegRXSDEQLECSbfJCQhH2c6ljwxxqass/TLdWzf43t9bfHdizCXAWbrHSyV4MPHT3h3c4P3d3e4vb3txWAwwHA41FYoV4JYiznW1hKe6yJNUyRJ0o841j5EOg7E48MjHu7v8fnpCaMvIzxPJpiMx715Ho/xMploP1rBaJRSqKoKp9MJZVnq/ls4Ho8QeZ7DcRw98L+NGoKftmn0QNM0OBwO2hLu2HVdL9iYnajrGq7rYjqdIssy+L6vhZh+HMdo21Yv7iOoI6SglBKz2UxHd631jVALMkwzcE2kTzPriqKA4IdVZqrsM92yKH6Pom3/GJ05Fh6Z4MfAihsobji/Suyfzxk4xzMXeZ5hs9kgDMNfUuWu5/BV0PFy3MDGGghWk+rmHIzYZUomAxbxrykHQQDP8zS84FEU6bd8CXcn1+YYPeeoJViQ5XKJ1WoF27a1IM+C9l+gDwsr0iTR99D3A51OVZ3e9I7py0hFGCeQKkCYZthHCfKqxvHUIK/6UmublTWSNIXYByGCMEIYxYjiBHXTomk7tB3Qtp3uX6M1azqA5SyqGhGvzUbu8LpW+LbZY/Rq4WW+wXwbwHJjWF4MGRVw4woyKn/C/21whK0OsFWKtTpgISPslA/huB6+WlssbInldoeV3MNylO7T2q5/lZWz12sMi7WLndrjB/qJ6PoY0hcqAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/b9c1f75b7d30276c30e268ca25aba88b/e93cc/05.webp 300w", "/static/b9c1f75b7d30276c30e268ca25aba88b/b0544/05.webp 600w", "/static/b9c1f75b7d30276c30e268ca25aba88b/e6bfe/05.webp 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/b9c1f75b7d30276c30e268ca25aba88b/eed55/05.png 300w", "/static/b9c1f75b7d30276c30e268ca25aba88b/7491f/05.png 600w", "/static/b9c1f75b7d30276c30e268ca25aba88b/6de40/05.png 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/b9c1f75b7d30276c30e268ca25aba88b/6de40/05.png",
                  "alt": "05",
                  "title": "05",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`The new property `}<em parentName="p">{`Product Assignments`}</em>{` appears.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "892px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/3b6bb7aa24f80a07bc9f6768b96425c2/6de40/06.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "98%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/0lEQVQ4y6WU+2rbMBjF9aYdCWR9jDH2Zulg/bejTcaWeGuJc7Ud27r4fsb5apUQ2jTZBD9kydJ3O5LUbJXgZxhh8rhCsMnw6fMXfLi6wsfra4xGo7MYDAYYDofSq3W4APk9+4XHYIZlGCJNU+z3+3dJ2SeJ7CGLxQLq9tstxuMxvt7c4PvdHX5Mp5g+PGA6mZwN95DJ/T1U27Ygrijwv62uayhrLTabDZIkQVmWKIoCVVX9k0HuF4PWGBhjoLVGnufS0/Al0BhtKRqI4xhRFIkxpt80jYR/KQxKOWex3W6x2+3EU9d1YpT9Ie81a6wEpGjEp0oPTN+n7eedc2hORMaac63UkIMsy2RjUThkWY76KGWW4BwYnDosqM7zl+hMLxThGl/bYzjv6yc1lDStlbQYLaGDS6BDGuUNU4xmvV7JVfLqHkfBOanjG6nyP4UTUTigUf441eid2ZxSXFJmZDyHhEeHG/O+lofQqRfvrf88y4rXbj6fIwgC6WmU15AO2Hvig+9j/FqeZ4lwuQzlPjNkiiLXyTnBnUlZFKKD0sYgeArxZ7FEnGpEaQ5dVLBVA1M2sFX7Kq4mXU+LsgXyXEPx2cpyjWSfYrXeYBfFMtbGwlgHL0F3AFvTdih5xJhRWaFu2ufHwYvCGjwfaN0XuheAQrwCxXupYxzLy82U/wKrPO9ZFQMQywAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/3b6bb7aa24f80a07bc9f6768b96425c2/e93cc/06.webp 300w", "/static/3b6bb7aa24f80a07bc9f6768b96425c2/b0544/06.webp 600w", "/static/3b6bb7aa24f80a07bc9f6768b96425c2/e6bfe/06.webp 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/3b6bb7aa24f80a07bc9f6768b96425c2/eed55/06.png 300w", "/static/3b6bb7aa24f80a07bc9f6768b96425c2/7491f/06.png 600w", "/static/3b6bb7aa24f80a07bc9f6768b96425c2/6de40/06.png 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/3b6bb7aa24f80a07bc9f6768b96425c2/6de40/06.png",
                  "alt": "06",
                  "title": "06",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
        <p parentName="li">{`In the `}<em parentName="p">{`Timeline`}</em>{` you may now add all content items to the video that can be shown as a teaser, for example, `}<em parentName="p">{`Articles`}</em>{`, `}<em parentName="p">{`Images`}</em>{`, `}<em parentName="p">{`Collections`}</em>{` and many more.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "892px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e72a73e66d0b7d54c6c2c12ff41f52d0/6de40/07.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "98%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/0lEQVQ4y51U2W7TQBSdPy1KpNC/QILv4B/goSBBXxDCQgUeIM3WzVEU2/E6jvc1B91LpqQhJUktHY/sO/fMXc5ccbuQuDZ9jGY2dCfCi5ev8OzkBM9PT9Hr9Q5Cp9NBt9vlVdimAdsyoN9eY6bfYWFZiJZLhKFEGIb7ISUs02QYcwNC+6Lh/OM5NE1Dv9/HcDDA8PISk9HoYFyNx7iaTDAaDiGqqkJeFCjLkpFlGYqiQNu2R2G1WrG/iOMIUkoEfoAoikAHkIFIN1Fuff9jL0ukaQpBL8uyYNsOG576kG8cxxAqmixNOV1CkiR8mgLZm6ZBXdc7QVkRGe1jQt/3ESxjpFnGBlVPBXIgwsegiLmGZVUhTSKY42+IwwBZlt9vpEIr7GsK7ecIq7pB6C3w9d1rxNJGXlRI0wR5nj8g/B+IkPYT1imH+Hn2Aa7tcA0p/EOi2oyOyKj2rEMZBBifvUfgemw8VoOK9A9h3UIubqAP3iCNJdeQDHTQoVE+iLBtavy6M/D5+w/4UnKnyKjUv68pq/VKfveEF4MbvP10gZlhMhlpb1MW2+S7mqL0K1i0VYl8LWIiJOO2sFVqj+lQBSLUrVBDQbX/WFDKNBOE67rQdR3z+Rye5/HPg+bgDgRB8JdwOp3yStdwyQP2aaSCXo7jcHR0AqVPZTgW5Efj7zeqn+tCxUjggwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/e72a73e66d0b7d54c6c2c12ff41f52d0/e93cc/07.webp 300w", "/static/e72a73e66d0b7d54c6c2c12ff41f52d0/b0544/07.webp 600w", "/static/e72a73e66d0b7d54c6c2c12ff41f52d0/e6bfe/07.webp 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/e72a73e66d0b7d54c6c2c12ff41f52d0/eed55/07.png 300w", "/static/e72a73e66d0b7d54c6c2c12ff41f52d0/7491f/07.png 600w", "/static/e72a73e66d0b7d54c6c2c12ff41f52d0/6de40/07.png 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/e72a73e66d0b7d54c6c2c12ff41f52d0/6de40/07.png",
                  "alt": "07",
                  "title": "07",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`For each of your content items in the `}<em parentName="p">{`Timeline`}</em>{`, click on the arrow on the left and enter the start time at which you wish the product to appear.
The content item will be shown when the video reaches the point in time you have just specified. It will be hidden when the start time of another content item in the timeline is reached. `}</p>
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` When the last content item in the timeline is reached, it will be shown until the end of the video.`}</p>
        </blockquote>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "892px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/8f825f1496264797975e935cb41130a8/6de40/09.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "98%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACAElEQVQ4y6VU0U7bQBD0n1IlUspnVFU/pxIPfelLgbYEnpBAQOwEUqBSHOI4cRL7znd27Klmg11UodZWTxr5cns7u7O7F2f8FOHWX8D9OcN9sMG79x/wZm8Pb/f30ev1GqHT6aDb7crXmfkTzKYT3I9v8fhjjKnvY71eY7VaNUMUiQ8xmUzgnJ2e4ujwEP2TE1xeXGA0HMJz3VYYeh5Gngd3MICT5zmUUkjTFGVZ4n8WuRxjDKIowmIRIo5jJEmCzWYje4J7Bttut+LwGrIsk3vkcvgjSRS0yWGMhdYaRVGiKAohaQKScgmhTlOEMx/u9wOswiniWAkpjSxBEzA4VQihsRbr5RyD/ifozRJZlsNaK5GbLpJSaS15S3k0ABKJpNXFpvgtWWsEQYjB129YhKEcsiaU0RRUw0QI6XIYLnDz8QDLYA6WoCpyG8n04YSI5KKESC7KEsakNWFbyRwzJzUW86cH+OMzaBVLce1zlm0k00cy5OzN/AeMLj8jiSMonUKpRC62kVwTZpl9Ydh1qhoZkpZ/yeylrZacZxb96zt8Ob/CfBlJpDQ1rbpM0E8IM2txffeI4/MrTIOgHtA2fxTVYHMEHTKjLLDNrETiIY3Vk2rT6d3TM0ZI4iSBIpSS4nKfPIN3qm4Wf4LneS5ymZy8FG6sMbvvKyDhv0BVTOYXlHP3PF1HsW4AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/8f825f1496264797975e935cb41130a8/e93cc/09.webp 300w", "/static/8f825f1496264797975e935cb41130a8/b0544/09.webp 600w", "/static/8f825f1496264797975e935cb41130a8/e6bfe/09.webp 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/8f825f1496264797975e935cb41130a8/eed55/09.png 300w", "/static/8f825f1496264797975e935cb41130a8/7491f/09.png 600w", "/static/8f825f1496264797975e935cb41130a8/6de40/09.png 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/8f825f1496264797975e935cb41130a8/6de40/09.png",
                  "alt": "09",
                  "title": "09",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`In the `}<em parentName="p">{`Preview`}</em>{` you can see your video in the section `}<em parentName="p">{`Banner (Hero)`}</em>{`.`}</p>
        <blockquote parentName="li">
          <p parentName="blockquote"><strong parentName="p">{`Hint:`}</strong>{` In case it is not shown, check if you can enlarge the `}<em parentName="p">{`Preview`}</em>{` area on your screen.`}</p>
        </blockquote>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "892px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/67c7a92b92247b5be0b307ad75d560d7/6de40/10.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "98%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAADnElEQVQ4y62U7U9bZRjGz3/jBz/p/KQhmS8QA3NlW+g6QAEJ4AfjDHHRYAwazNDAJhJGWAIS5hwIY2QVhLa0paWFooOVdfTt9IW2UNpRoLSlTDb4mXM6yKbfxJP8ct3PfZIruZLreQQxuoF/bRMxmmBla5emy81UVHxAXV0dNbW1L1JTQ21tLdXV1VRWVlJVVSXrh8+dhcSjOOnkFttbGyQ3EiS3tkilUmQyGTLp9L/YyWR4FI8TXY0SjUZZiUTYSCRwPHDgcroQJnU6xsfGMExOotfpMOh0mI1GLGYzFrMJi8kkz9Mm0xHS2To9fcSs1crI8DCjajWC5Ox0ugn4AwQDAVl9Pj+iV5TxiRJeAn4/Ad+z2edD9Hhwu5x43C4Z0euVd0IquYH6Zjt3+ruZMk5g0d/Fdc+A32Un6Hfj8Pqwu7zMzi9ic/ixL3kwmq1E12L88zs4OECIr4ZpvfQ+3zd+Ql9fJ7dvtHF/agjXghnRbWfRI7IQiNJ99Vtqykv5TaPnZv8goj/AbipGbHmebHpdNnz6dB8hEVuh58rndLU10dvVinrgGo7pIfwOC5FlD+HoGmvbWYa7Wqh66xVuD/UzNqHF7XZhN/XiWRjB59AREWd48uQvhN2dbRy2MdqaG2lsuMT4rQ7mNT8ReGggsuwiGAmxlkpzp+kL2pX53OpsZnRUTTAYZDMewmsf576hE9/iKHt7jxHS6W20g530XP6UgSv1qH/8DFPPNzzQdqPp+4rZ4R8IeRawDnbR8dEFBq638/vIz4SXfbmY+/B474ADYH9/HyEWjVCieBfFqSIqSk5zsbqUzu++ZO7uNZY013Hrf2X8aj2jvS38MjhIS1sHN7paCYoudrJZggE/4XCIxPp6ztDr9ZL3xuso3ivkZF4eBSff5LVXT3Di5Zf4uv5jGhsauFhdhvJsMaryKt5+p4BzxUVYzFOygVTuWCzG5uZmzjASiaBSqSgrLUWpVKJQKDh3tpiC/HxOFxWiUiopKy+n5PwFis+cQXVexamiQoxGoxw5Ho/LhtlsNlcbl9PJQ4cD59ISXo9HLqikUqGlYkuzx+1+4d/h7Pf5coiiXHxpJ+g0GvnqTWq12GZm5Gt0iG3GKu8OmbXOPNvndMZikZk9VKsVYcpgQDsxgVGv5w+b7dgcGUr659zc8Q2lqFJkKfrz8f4rQjgUYjkYJBwOyxWIrq4eC0F+SDMZ0pkMqXSa9DERkskk/yd/A9HUI2GY+dnKAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/67c7a92b92247b5be0b307ad75d560d7/e93cc/10.webp 300w", "/static/67c7a92b92247b5be0b307ad75d560d7/b0544/10.webp 600w", "/static/67c7a92b92247b5be0b307ad75d560d7/e6bfe/10.webp 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/67c7a92b92247b5be0b307ad75d560d7/eed55/10.png 300w", "/static/67c7a92b92247b5be0b307ad75d560d7/7491f/10.png 600w", "/static/67c7a92b92247b5be0b307ad75d560d7/6de40/10.png 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/67c7a92b92247b5be0b307ad75d560d7/6de40/10.png",
                  "alt": "10",
                  "title": "10",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`If you do not want your first content item to be shown straight away, you can define a `}<em parentName="p">{`Default Product`}</em>{` above the `}<em parentName="p">{`Timeline`}</em>{`. For example, you can show a `}<em parentName="p">{`Teaser`}</em>{` until your first content item appears in the video.`}</p>
        <p parentName="li"><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "892px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/b4bdcaee5f03c3d52069cc91b1069d16/6de40/11.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "98%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAACM0lEQVQ4y6WUzWsTQRjG5y/zpHgQURD0IlYSiD0L3kXEuwfBilrqBxW8iR568NBGDzGkMSlptulHSmmym91kvz9md7P7yDvJpj0UsrEDPwZ2Zp55n/d9d9ihYmC/P0L7REFXtfH02XM8XFrCo+VllEqlXBQKBRSLRTGzQb8H4vjoACfdQ8j9PizLgmmac7FoNgxxhuj1emCVSgXlrS38KpfRbDbRkSRIu7uQ2u3c7EmSoN1qgSVJgnGSIAgCXHbEcQzm+z5UVYWh6wjDEJxzRFGUWyRNUzHTWUqDEMzwPA+O48B1XSG8CCRI5xkJUHQjXRdCIgXjsQg/L+SIzlBQLPB9yMoAA1kGD/xL5ZCiZEeag5cbNbzY3MOD9R3c/dDEvY9N3Flr4Mbb+lxuvqvj6koNT77vw7QdsK5m4/2P33i9uYP76y3cWm3g9upfsfHaSm0u199s48qrKh5/68CwbLA4imBYDjwvhB9wuJ6HeDzJ4yJQrakGjPMQtjFCt/4TjqEhCDjoknEci5ZI02RKegHT71TIOJ4IBpxDU06xvfEJrqGJKKn8lGC6NRdpKlpHCFLJp70pFmmBWoEGtdDFkZ2R7aFBLcjolxsOdXT+VGEa5kyQNi7CrLFJUFU1ND5/gSYrCKNQJHmRkVkmZpajdGLxvOV5djOyIQSpqrrWw7AviReHoF9oEdvkaBYh5xGU0wO0q1/hWCPwMBKitPH8azLPcnhmORSNzKPJTSSWPV//Y/kfqN3PTphwPs0AAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/b4bdcaee5f03c3d52069cc91b1069d16/e93cc/11.webp 300w", "/static/b4bdcaee5f03c3d52069cc91b1069d16/b0544/11.webp 600w", "/static/b4bdcaee5f03c3d52069cc91b1069d16/e6bfe/11.webp 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/b4bdcaee5f03c3d52069cc91b1069d16/eed55/11.png 300w", "/static/b4bdcaee5f03c3d52069cc91b1069d16/7491f/11.png 600w", "/static/b4bdcaee5f03c3d52069cc91b1069d16/6de40/11.png 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/b4bdcaee5f03c3d52069cc91b1069d16/6de40/11.png",
                  "alt": "11",
                  "title": "11",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`You can add the `}<em parentName="p">{`Shoppable Video`}</em>{` to all content items that support video items.
For example, you could add it to the `}<em parentName="p">{`Placements`}</em>{` on a Page. `}</p>
        <p parentName="li">{`   `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "893px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/e6e4c598074ced2e0f5fcc52df31634d/fa61a/12.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "84.66666666666669%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB0UlEQVQ4y62Uy27aQBiF5/HaLri9SKVKXTcL81JVV5FC1TRV6AIIIlUSCLZsY7DH2DO+caozwoggaJK2lj6P5/Zfzj9jYVkWLKuLbreLs7NP+PKtj7GX4P2Hj3j39g06nQ5a7fZR2mxbLTQaDTSbTYO4v7uD4zi4ndwaXMeGWsfwXBf2fA7HtmGfgPumDw8Yj8dm72QygehdXOBrr4cfV1f42e/j++Ul+tfXGA4GGL2E4RA3o9GuL1arFVzXRVmWqKoKm83GwP5r4F6tNYRSCqnS0FmGbAsnSEb2xk/BtXmeI01TCCkjLF0HSSyhtobojdTei6J4lrIoweCETDQ+/5IYTH3Ysyke53MjQRAEUCo1i/alqDl0yD6jFYnOcX4fY7ZMUWTaREkjepsuUzk0xoeG6nVMtU5flGUBHYeoinzneT+CPxWolobwMQb5YlRZniOWElJK43EZLEF9a8H3UzwG12ilITKdGQNxHCNJEsN6vX7y/ZxBzpnTwipzwPc8eJ6H6kCrYwU4xS5lVigKQywWi92Ree2hrqPcFqVEECzg+z7SNPlrg8zEGGTezJ86GWFfeDsObwpbai54iGezR4RhCN5rtv+CoHb8DUVR9F/4DeSn/yixrGQ4AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/e6e4c598074ced2e0f5fcc52df31634d/e93cc/12.webp 300w", "/static/e6e4c598074ced2e0f5fcc52df31634d/b0544/12.webp 600w", "/static/e6e4c598074ced2e0f5fcc52df31634d/8c4fa/12.webp 893w"],
                  "sizes": "(max-width: 893px) 100vw, 893px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/e6e4c598074ced2e0f5fcc52df31634d/eed55/12.png 300w", "/static/e6e4c598074ced2e0f5fcc52df31634d/7491f/12.png 600w", "/static/e6e4c598074ced2e0f5fcc52df31634d/fa61a/12.png 893w"],
                  "sizes": "(max-width: 893px) 100vw, 893px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/e6e4c598074ced2e0f5fcc52df31634d/fa61a/12.png",
                  "alt": "12",
                  "title": "12",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
        <p parentName="li">{`   `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "892px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2760419d9d1ecdbdbf2d65f0e2c6747c/6de40/13.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "84.66666666666669%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAAAsTAAALEwEAmpwYAAACDUlEQVQ4y62U227TQBCG/XrlIqf3QEI8QS6Sh+IGCamKUFtEQGraEkMSktjY8Xlt79q76x/N5qAqJBAElj6Px9457qytwWCA4XCI4XCAfr+Pt7ef8fiD4eWr13hxdYVer4dOt/srnQ663S7a7TZarZaRhDWfzeC6LuzpFLZtw3Md8JzB9zw46zVcx4FzBrJbLBb48vRkbKfTKazR9TXej0b4cHeHT+Mxbm9uMP44xuT+Hg+XMJng8eHhoFtxFMHzfCiloLVG0zQG0v8GsuWcw6JbyTlEVaHaIYQwVMSz9+egtXVdoyxLWFmWIvJd5CwD3zmgaMQ+upTyj9A64zAtBN7YGSZLH87yO1brNTzPQxiGZoEQHFo3h1bsOQ5IOiVj5bzGuznDKi4hK2GypDaIXbZUyrEzusjRfh0Frqp661BLiYolaFQNkIHWRmpqtFJGN89aHd7tdfpmaLaYTSlFhaQQyEWNMMkQJgys5AjiFAnLwWsJXivwWqOs1UmE1IjSHFGcwCp5hYQViFJmJGF//YbZfI7lcmVkECcQqjHG/AxpsZ0WS0mJje9js9mYUokiz5FlGRhjRgrOt6VrdRqlTF9ND6WskSQJgiCAEBXUs+E+taO/G2zjUClpnAVBiKIoLjI+BQU2DmnLOd9OOiEuPB3HJ4UkJWTREK9WK1N2HMdG/gsWlUu/oTRN/ws/AYzj/K4uPgCTAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/2760419d9d1ecdbdbf2d65f0e2c6747c/e93cc/13.webp 300w", "/static/2760419d9d1ecdbdbf2d65f0e2c6747c/b0544/13.webp 600w", "/static/2760419d9d1ecdbdbf2d65f0e2c6747c/e6bfe/13.webp 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/2760419d9d1ecdbdbf2d65f0e2c6747c/eed55/13.png 300w", "/static/2760419d9d1ecdbdbf2d65f0e2c6747c/7491f/13.png 600w", "/static/2760419d9d1ecdbdbf2d65f0e2c6747c/6de40/13.png 892w"],
                  "sizes": "(max-width: 892px) 100vw, 892px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/2760419d9d1ecdbdbf2d65f0e2c6747c/6de40/13.png",
                  "alt": "13",
                  "title": "13",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
        <p parentName="li">{`   `}<span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "0",
              "marginRight": "0",
              "maxWidth": "893px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0f91eadbe9f2cfed6ec21586055377f0/fa61a/14.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "98%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAFL0lEQVQ4y22UaUwUVhSF53+jP6rRNq1YdxDEUve4YGtxrU0suFQIMOPAFOoCtqKgKNqKIosoEazLBBcQBcWlgoI6ioIw4+wMLgWroKwOsyHbzPC1DLaNSW9y8s7Lyz05ue/eK8jKyuLYsWNkHD7E5evFtHXBtZu3EYaGIpFICA8Pfw9isRihUIhIJCIoKIjg4GAXDw0Ndb0LLBYLZrPZhTdtrRhbWzAZ32AxW7BaBmAxm7G8422trTTU19P4uhG1Usmzp095XleHQq7A1N6OQKVUopDLUSoUVOv1aDVatFotjw0GdFot1Tqdi/efNQYDep0Og17P45oaF549eUJFeTmlN0vQajQIamtr6Ue/w/6w2+10dnbS09M94Mpqpae3lw6bje7uHtrbjZhMJmwdHVitNmxWC2+MRixWK01NTQj4n7A77BhNJhxOJ3Z7r0vQ6XTQ19dHR+dbHA4HOB3vJ/U5qK9vQBAT4s/PwhA2BAUSvjaQ7eERHInfy7nUA+xct5GtgRLiQyPYELgO8ZoAknfEoparSH5mJahQzQ7pJeLK60hQttD4qgHBgtHDmek+nmHDRzJk6Kd86TWOb3xmslfsz77ItYSuXMPSZUEs9vNnc0QUl6S5lJXIEN19yYa0M+TGb+Tgxat8L3vF6/qXCDb5+rB29hQ+GenB8lmebA3wQeTnzfoVC8iODSYzYRsLA8JITz2C/E4FF46fRiZ7yJa8Cm6XyNDISlHqdQSVvcbY1oag6JdYDom+Y9IED0L8vIlfO50DYXM4nbabgsx0jktWsSt+G5FRWzmfJaXo/GUqKzQczrvD5asl3Dx7hivnzhBTqqerqwtBYVwUO1cu5XPPyaz2ncvKGd6kbJGgfnCPpwYDlUW/ky0JJHDpCoSB68lJy0RxX8GL+kbu3JKhrHxETfldHsvL6ejoQCCcP5fxo9yZPccPf99FLPCeQmpcHPfyTqKvUlCUfwHhwkWs+GoJ82f5sn5VAGVFpa6PNZnaqW+ox2S14ezr422/4LdTp+I3bQY+Hj54jPNk7Ch3NgcFkrt7MzGSMObO+ZoJk2biNWEys7y8WODtzfEDSS5B67ves1mtrrvL4fSJHizzncdML08mjh7NZ25uuI90Y+E0b6Z7jmfQB4MY9uEQRnz0MWPd3BgxZCibIyJdAs3NzdTV1dHS0jLQo/2CVXI5Ko2G6poa9NXV6HQ6VGo1SpUalUqFrn8cdTqUSiVqtZrKqir+qK11CTqdzv/6+h+H1Q/vUXmrmJNpKdwoyKO08BwvnhrIzc2l3WymJCebP7WPXEn9i+HfaertwmHv5uLZU8iKr9DVYcRmsyGQFeayLyYK0aJ55BxK4uje7ZTfKiYiMhK1TsfJxJ1IYzfR8PwFhhoD3d1d9PZ243D00GNpZX/4KvZESzC2PHfVVFBVcoV9cdvYHSJCf+sy57PS0FSWIxKtQ63VcunwQaJ9viA/NZ2HCoVrhYGdt502mmr1XE/fhaKsmMe6qgGHBccPk5m4hwRhCNdzTpBzJBVVRRkisZj8s2fJ2BLDiT2JlJWWsj8pieamRsymNkKEPyAOFpK2/ScSf93DjWuFAzUsyD5KfvZJMhMPkPtbBkf376aqTIY4LIzCixe5kJ/PMamUouJiTp06hc1q5mZxEaPGeLE6YDUxUVG4u3uRkZ7mWnsCpbwSjVJJ5YNyknbGkZoQy/3bJawTi8nOziYjI4Po6GikUikpKam0tjSTkpzM6DEeLF2ynMWLljB40GA2RP5I19+CfwF36pzZ0/CZBwAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/0f91eadbe9f2cfed6ec21586055377f0/e93cc/14.webp 300w", "/static/0f91eadbe9f2cfed6ec21586055377f0/b0544/14.webp 600w", "/static/0f91eadbe9f2cfed6ec21586055377f0/8c4fa/14.webp 893w"],
                  "sizes": "(max-width: 893px) 100vw, 893px",
                  "type": "image/webp"
                }}></source>{`
          `}<source parentName="picture" {...{
                  "srcSet": ["/static/0f91eadbe9f2cfed6ec21586055377f0/eed55/14.png 300w", "/static/0f91eadbe9f2cfed6ec21586055377f0/7491f/14.png 600w", "/static/0f91eadbe9f2cfed6ec21586055377f0/fa61a/14.png 893w"],
                  "sizes": "(max-width: 893px) 100vw, 893px",
                  "type": "image/png"
                }}></source>{`
          `}<img parentName="picture" {...{
                  "className": "gatsby-resp-image-image",
                  "src": "/static/0f91eadbe9f2cfed6ec21586055377f0/fa61a/14.png",
                  "alt": "14",
                  "title": "14",
                  "loading": "lazy",
                  "decoding": "async",
                  "style": {
                    "width": "100%",
                    "height": "100%",
                    "margin": "0",
                    "verticalAlign": "middle",
                    "position": "absolute",
                    "top": "0",
                    "left": "0"
                  }
                }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
      </li>
    </ol>
    <h2>{`How to go on`}</h2>
    <p>{`Congratulations, you have now learned how to create and edit a Shoppable Video. To get more information, please visit our `}<a parentName="p" {...{
        "href": "https://documentation.coremedia.com"
      }}>{`documentation section`}</a>{` and have a look at the Studio User Manual.`}</p>
    <p>{`In case you have not done so already, we recommend this short video about Shoppable Videos:`}</p>
    <p><div parentName="p" {...{
        "className": "gatsby-resp-iframe-wrapper",
        "style": {
          "paddingBottom": "56.49999999999999%",
          "position": "relative",
          "height": "0",
          "overflow": "hidden"
        }
      }}>{` `}<div parentName="div" {...{
          "className": "embedVideo-container"
        }}>{` `}<iframe parentName="div" {...{
            "title": "",
            "src": "https://www.youtube-nocookie.com/embed/f7Ztid10U4Y?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0",
              "position": "absolute",
              "top": "0",
              "left": "0",
              "width": "100%",
              "height": "100%"
            },
            "loading": "disabled",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{` `}</div>{` `}</div></p>
    <p>{`Need support? Do not hesitate and reach out at `}<a parentName="p" {...{
        "href": "mailto:support@coremedia.com."
      }}>{`support@coremedia.com.`}</a>{` `}</p>
    <p>{`And if you notice any problems, or find bugs, our Support Team will be glad to help as well.`}</p>
    <p>{`You can also visit the CoreMedia `}<a parentName="p" {...{
        "href": "https://support.coremedia.com"
      }}>{`Help Center`}</a>{`. It contains a Knowledge Base along with the section "Technical Videos". Here you will find more technical related videos and short tutorials.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      